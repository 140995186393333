export default [
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    open: true,
    tag: '',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'System',
        route: 'settings-system',
      },
      {
        title: 'Exchange rates',
        route: 'dashboard-currencies',
      },
    ],
  },
]
