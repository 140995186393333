<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <h2 class="brand-text">
                <PnxLogo />
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import store from '@/store/index'
import PnxLogo from '@/views/ui/Pnx.vue'
import navMenuItems from '@/navigation/vertical'
// import user from '@/navigation/vertical/user'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    PnxLogo,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const userData = ref(JSON.parse(localStorage.getItem('userData')))

    function routeParams() {
      let userId
      if (store.getters['verticalMenu/accountId'] !== '') {
        userId = store.getters['verticalMenu/accountId']
      }

      if (userData.value && Object.keys(userData.value).length !== 0) {
        userId = userData.value.user_id
      }

      return { userId }
    }

    const userMenu = ref({
      title: 'Sites and widgets',
      icon: 'HomeIcon',
      tag: '',
      tagVariant: 'light-warning',
      children: [
        {
          title: 'Main',
          route: {
            name: 'dashboard-users-page',
            params: routeParams(),
          },
          resource: 'Sites and widgets',
          action: 'read',
        },
        {
          title: 'My sites',
          route: {
            name: 'dashboard-user-sites',
            params: routeParams(),
          },
          resource: 'Sites and widgets',
          action: 'read',
        },
        {
          title: 'My advertisement',
          route: {
            name: 'dashboard-user-campaigns',
            params: routeParams(),
          },
          resource: 'Sites and widgets',
          action: 'read',
        },
      ],
    })

    if (userData.value.is_agency) {
      userMenu.value.children.push(
        {
          title: 'My partners',
          route: {
            name: 'dashboard-user-agencies',
            params: routeParams(),
          },
          resource: 'Sites and widgets',
          action: 'read',
        },
      )
    }

    const userButton1 = ref({
      title: 'sites.siteadd',
      icon: 'PlusIcon',
      tag: '',
      class: 'btn-add',
      tagVariant: 'light-warning',
      route: {
        name: 'dashboard-user-site-create',
        params: routeParams(),
      },
      action: 'read',
    })

    const userButton2 = ref({
      title: 'campaigns.campaignadd',
      icon: 'PlusIcon',
      tag: '',
      class: 'btn-add',
      tagVariant: 'light-warning',
      route: {
        name: 'dashboard-user-campaigns-create',
        params: routeParams(),
      },
      action: 'read',
    })

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName } = $themeConfig.app

    return {
      navMenuItems: [userMenu.value, userButton1.value, userButton2.value, ...navMenuItems],
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.navbar-brand .brand-text {
  color: #5e5873 !important;
}
</style>
