export default [
  {
    title: 'Admin',
    icon: 'ShieldIcon',
    open: true,
    tag: '',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Notifications',
        route: 'dashboard-notifications',
      },
      {
        title: 'Users',
        route: 'dashboard-users',
      },
      {
        title: 'Sites',
        route: 'dashboard-sites',
      },
      {
        title: 'Campaigns',
        route: 'dashboard-campaigns',
      },
      {
        title: 'Pixels',
        route: 'dashboard-pixels',
      },
      {
        title: 'Partners',
        route: 'dashboard-agencies',
      },
      {
        title: 'RTB partners',
        route: 'dashboard-partners',
      },
      {
        title: 'DSP partners',
        route: 'dashboard-dsppartners',
      },
      {
        title: 'Prebid partners',
        route: 'dashboard-prebidpartners',
      },
      {
        title: 'Reports',
        route: 'dashboard-reports',
      },
      {
        title: 'Adsense incomes',
        route: 'adsense-incomes',
      },
    ],
  },
]
