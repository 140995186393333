export default [
  {
    title: 'Utils',
    icon: 'GridIcon',
    open: true,
    tag: '',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Decode url',
        route: 'decode-url',
      },
    ],
  },
]
